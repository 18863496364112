<template>
  <div class="">
    <am-list-page
      title="Contribution Payments"
      :columns="columns"
      :rows="payments"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      enable-multi-select
      show-profile-photo
      photo-key="contributor_photo"
      @onRowSelect="handleSelectedRow"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-select
            v-model="selectedContributionId"
            placeholder="Select Contribution"
            class="mr-1"
            label-name="setting_label"
            value-name="id"
            :options="contributions"
            style="width: 200px !important;"
          />
          <am-button
            variant="outline-primary"
            icon="PrinterIcon"
            label="Receipts"
            tool-tip-text="Generate receipts for selected payments"
            class="mr-1"
            @click="generateReceipts()"
          />
          <am-button
            icon="SettingsIcon"
            label="Payments"
            tool-tip-text="Setup & Manage Contribution Payments"
            class=""
            @click="showPaymentSetupModal()"
          />
        </div>
      </template>
      <template #action="{row}">
        <am-button
          icon="Edit2Icon"
          icon-only
          tool-tip-text="Click to edit"
          variant="flat-primary"
          @click="showPaymentModalForEdit(row.item)"
        />
      </template>
      <template #amount="{row}">
        {{ row.item.amount | currency }}
      </template>
    </am-list-page>
    <payment-setup
      :id="selectedPaymentId"
      :modal-opened="paymentsModalOpened"
      @modalClosed="closeModal('payments')"
    />
  </div>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import PaymentSetup from '@/views/contribution/payments/ContributionPaymentSetup.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import downloader from '@/farmbusiness/mixins/downloader'

export default {
  name: 'Index',
  components: {
    AmSelect,
    PaymentSetup,
    AmListPage,
    AmButton,
  },
  mixins: [showToast, downloader],
  data() {
    return {
      tableLoading: false,
      paymentsModalOpened: false,
      selectedPaymentId: null,
      selectedContributionId: null,
      selectedPayments: [],
    }
  },
  computed: {
    paymentSettings() {
      return this.$store.getters['contributionPayments/paymentSettings']
    },
    payments() {
      return this.$store.getters['contributionPayments/contributionPayments']
    },
    contributions() {
      return this.$store.getters['contributionSettings/contributionSettings']
    },
    currencySymbol() {
      return this.$store.getters['company/currencySymbol']
    },
    columns() {
      const symbol = this.currencySymbol
      return [
        {
          label: '',
          key: 'multiselect',
        },
        {
          label: 'Receipt No',
          key: 'payment_id',
        },
        {
          label: 'Photo',
          key: 'contributor_photo',
        },
        {
          label: 'Contributor',
          key: 'contributor_name',
        },
        {
          label: `Payment Amount(${symbol})`,
          key: 'amount',
        },
        {
          label: 'Mode of Payment',
          key: 'payment_mode',
        },
        {
          label: 'Transaction Date',
          key: 'display_payment_date',
        },
        {
          label: 'Actions',
          key: 'action',
        },
      ]
    },
  },
  watch: {
    selectedContributionId(val) {
      this.$store.commit('contributionPayments/setSettings', { selectedContributionId: val })
      if (!val) {
        this.$store.commit('contributionPayments/setContributionPayments', [])
      } else {
        this.loadPayments()
      }
    },
  },
  mounted() {
    this.selectedContributionId = this.paymentSettings.selectedContributionId
    this.loadPayments()
  },
  methods: {
    handleSelectedRow(items) {
      this.selectedPayments = items
    },
    showPaymentModalForEdit(item) {
      this.selectedPaymentId = item.id
      this.paymentsModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'payments':
          this.paymentsModalOpened = false
          this.selectedPaymentId = null
          break
      }
    },
    showPaymentSetupModal() {
      if (this.selectedContributionId) {
        this.paymentsModalOpened = true
      } else {
        this.showInfo('Please select a contribution')
      }
    },
    loadPayments() {
      if (this.selectedContributionId) {
        this.tableLoading = true
        this.$store.dispatch('contributionPayments/fetchContributionPayments', this.selectedContributionId).finally(() => {
          this.tableLoading = false
        })
      } else {
        this.$store.commit('contributionPayments/setContributionPayments', [])
      }
    },
    generateReceipts() {
      if (this.selectedContributionId) {
        if (this.selectedPayments.length) {
          this.tableLoading = true
          this.$store.dispatch('contributionPayments/generateReceipts', { id: this.selectedContributionId, ids: this.selectedPayments.map(p => p.id) })
            .then(url => {
              if (url) {
                this.downloadFile(url, 'Payment Receipts')
              }
            })
            .finally(() => {
              this.tableLoading = false
            })
        } else {
          this.showInfo('Please select at least one payment')
        }
      } else {
        this.showInfo('Please select a contribution')
      }
    },
  },
}
</script>

<style scoped>

</style>
